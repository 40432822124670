<template>
  <div>
    <!-- Show the form dialog -->
    <v-dialog
      :value="value"
      @input="(v) => $emit('input', v)"
      :persistent="isMakingRequest"
      max-width="540"
      scrollable
    >
      <v-card>
        <v-card-title class="primary pb-4">
          <div class="white--text">
            <!-- Show the text -->
            Edit Story
          </div>
        </v-card-title>

        <v-card-text>
          <story-form
            :overview="overview"
            :model="model"
            :is-making-request="isUpdating"
            @submit="handleFormSubmit"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Import children components
const StoryForm = () => import(/* webpackChunkName: "crm-story-form" */ "@/components/crm/StoryForm.vue")

// Export the SFC
export default {
  // Name of the component
  name: "EditStory",

  // Register children components
  components: {
    StoryForm
  },

  // Accept incoming data from parent
  props: {
    // The entire campaign object
    overview: {
      type: Object,
      required: true
    },

    // Whether or not to show the dialog
    value: {
      type: Boolean,
      required: true
    },

    // The story model object
    model: {
      type: Object,
      required: true
    },

    // The Vuex store action to call
    module: {
      type: String,
      required: true
    },
  },

  // Define local data variables
  data: () => ({
    // Whether or not is a network request being made
    isMakingRequest: false,
    isUpdating: false,

    // Module and API endpoint map
    moduleMap: {
      campaignTracking: "campaign-tracking",
      influencerOutreach: "influencer-outreach"
    }
  }),

  // Define local method functions
  methods: {
    /**
     * Hide all the dialogs
     *
     * @returns {void}
     */
    doHideDialogs() {
      // Hide the confirmation dialog
      this.shouldShowConfirmationDialog = false

      // Hide the form dialog
      this.$emit("input", false)
    },

    /**
     * Handle the create submission request
     *
     * @param {Object} formData
     * @returns {void}
     */
    async handleFormSubmit(formData) {
      // Show a loader
      const loaderId = Symbol()
      this.isUpdating = true
      this.isMakingRequest = true
      this.$store.dispatch("loaders/add", loaderId)

      // Try making a network request
      try {
        // Push the method in the object
        formData.append("_method", "PUT")

        // Use helper function to make the request
        const response = await axios({
          url: `/api/${this.moduleMap[this.module]}/${this.overview.model.id}/stories/${this.model.id}`,
          method: "POST",
          data: formData
        })

        // If received a response, push it to the store
        this.$store.dispatch(`${this.module}/updateStory`, { id: this.overview.model.id, story: response.data })

        // Show a toast message
        this.$store.dispatch("toasts/add", { text: "Story values updated!" })

        // Dispatch an event to refresh the values
        window.dispatchEvent(new CustomEvent("campaignTracking:refreshStories", { detail: this.overview.model.id }))
      }
      // Catch any error
      catch (error) {
        // Use global logger helper function
        logger({ type: "CRM/EditStory Error", error })

        // Show a toast message
        this.$store.dispatch("toasts/add", { text: error?.response?.data?.message || "An error occurred, please try again" })
      }
      // Nonetheless
      finally {
        // Hide the loader
        this.isUpdating = false
        this.isMakingRequest = false
        this.$store.dispatch("loaders/remove", loaderId)

        // Also hide the dialog
        this.$emit("input", false)
      }
    }
  }
}
</script>
